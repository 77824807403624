<template>
  <div>

    <p v-html="page.inleiding"></p>

    <v-layout pa-2 class="question" mt-5 wrap>
      <v-flex xs12>{{ aantalVraag.omschrijving }}</v-flex>
      <v-flex xs12>
        <v-slider max="40" v-model="aantalVraag.reactie" thumb-label="always">
        </v-slider>
      </v-flex>
    </v-layout>

    <v-layout pa-2 class="question" mt-5 wrap v-for="(v, index) in silderSubVragen" :key="index">
      <v-flex xs12 sm7><b> {{ v.omschrijving }}</b><br> <span>Nog te verdelen uren: {{ teBesteden}}</span></v-flex>
      <v-flex xs12 pl-3 sm5>
        <v-slider :key="index" thumb-label="always" :max="maximus" v-model="v.reactie">
        </v-slider>
       
      </v-flex>
    </v-layout>
  </div>
</template>



<script>

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Tijdsbesteding",
  props: ["page"],
  data: () => ({
    aantalVraag: null,
    sliderVraag: null,
    silderSubVragen: [],
  }),
  methods: {
    ...mapActions(["updateAntwoorden"]),

    submitNext() {
      let res = [];

      res.push({ id: this.aantalVraag.id, reactie: this.aantalVraag.reactie });

      const rsub = this.silderSubVragen.map((m) => {
        return { vraag_id: m.id, reactie: m.reactie };
      });

      res.push({ id: this.sliderVraag.id, reactie: rsub });

      this.updateAntwoorden(res);
    },
  },
  computed: {
    ...mapGetters({
      vragenlijst: "vragenlijst",
    }),
    maximus() {
      return (this.aantalVraag && this.aantalVraag.reactie) || 0;
    },
    teBesteden() {
      let w = 0;

      this.silderSubVragen.forEach((fe) => {
        w += fe.reactie;
      });

      return this.maximus - w;
    },
  },
  watch: {

    teBesteden: function (val) {
      this.$emit("pageValidChanged", val === 0);
    },

    page: {
      immediate: true,
      handler(nv) {
        if (nv !== null) {
          this.aantalVraag = this.vragenlijst.find((f) => f.id === 106);

          this.sliderVraag = this.vragenlijst.find((f) => f.id === 93);

          this.silderSubVragen = this.vragenlijst
            .filter((f) => f.soort === 'uitklap_form' && f.reactie == true)
            .map((m) => {
              let r = null;

              if (this.sliderVraag.reactie != null) {
                r = this.sliderVraag.reactie.find((f) => f.vraag_id === m.id);
              }

              return {
                id: m.id,
                omschrijving: m.omschrijving,
                reactie: (r && r.reactie) || 0,
              };
            });
        }
      },
    },
  },
};
</script>


<style scoped>
.question {
  background-color: #f0f0f0;
}

p {
  color: black;
}
</style>